.contactDialog-shadow {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.contactDialog-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  animation-duration: 0.3s;
  animation-name: fadeIn;
  z-index: 1001;
}

.contactDialog-card-container {
  width: calc(100% - 2rem);
  margin: 0 auto;
  max-width: 750px;
  border-radius: 7px;
  position: relative;

  #teamsDefault & {
    background-color: rgb(233, 233, 233);
  }

  #teamsDark & {
    background-color: rgb(43, 42, 42);
  }

  #teamsHighContrast & {
    background-color: black;
  }
}

.contactDialog-top-container {
  width: 100%;
  height: 60px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0 1.5rem;
  #teamsDefault & {
    background-color: rgb(91, 95, 199);
  }

  #teamsDark & {
    background-color: rgb(98, 100, 167);
  }

  .contact-card-picture,
  .contact-card-no-picture {
    width: 80px;
    height: 80px;
    transform: translateY(20px);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    object-fit: cover;
    z-index: 100;

    #teamsDefault & {
      border: 3px solid white;

      &:hover[data-read-only~="false"] {
        background-color: white;
        cursor: pointer;
      }
    }

    #teamsDark & {
      border: 3px solid rgb(37, 37, 37);

      &:hover[data-read-only~="false"] {
        background-color: rgb(46, 46, 46);
        cursor: pointer;
      }
    }
  }

  .contact-card-no-picture {
    border-radius: 50%;
    #teamsDefault & {
      background-color: rgb(240, 240, 240);
    }

    #teamsDark & {
      background-color: rgb(32, 31, 31);
    }
  }
}

.contactDialog-bottom-container {
  height: 600px;
  overflow-y: scroll;
  width: 100%;
  padding: 3rem 1.75rem;
  position: relative;

  #teamsDefault & {
    background-color: rgb(245, 245, 245);
  }

  #teamsDark & {
    background-color: rgb(37, 36, 35);
  }
}

.contactDialog-btns-modify-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.contactDialog-tabs-content {
  overflow-y: scroll;
  height: min-content;
  padding-bottom: 4rem;
}

// Contact tab

.contactDialog-contact-tab-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}

// Company tab

.ui-dropdown,
.ui-dropdown__container,
.ui-dropdown__items-list {
  width: 100% !important;
}

// Group tab

.contactDialog-groups-list-container {
  min-height: 100px;
  border-radius: 8px;
  padding: 0.75rem;

  #teamsDefault & {
    background-color: rgb(240, 240, 240);
  }

  #teamsDark & {
    background-color: rgb(32, 31, 31);
  }
}

.contactDialog-group-pill {
  padding-left: 0.75rem;
  border-radius: 20px;

  #teamsDefault & {
    background-color: rgb(245, 245, 245);
  }

  #teamsDark & {
    background-color: rgb(37, 36, 35);
  }
}

@media screen and (max-width: 1024px) {
  .contactDialog-contact-tab-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .contactDialog-card-container {
    width: 100%;
    height: 100vh;
    max-width: none;

    #teamsDefault & {
      background-color: rgb(245, 245, 245);
    }

    #teamsDark & {
      background-color: rgb(37, 36, 35);
    }
  }

  .contactDialog-top-container {
    border-radius: 0;

    #teamsDefault & {
      background-color: rgb(91, 95, 199);
    }

    #teamsDark & {
      background-color: rgb(98, 100, 167);
    }

    .contact-card-picture,
    .contact-card-no-picture {
      width: 70px;
      height: 70px;
      left: 75%;
      position: relative;
      transform: translate(-50%, 20px);
    }
  }

  .contactDialog-titles-container {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.75rem 0 0 0;

    .contactDialog-titles {
      width: 100%;
      display: flex;
      justify-items: center;
      align-items: center;
      div,
      input {
        width: 100% !important;
      }
    }
  }

  .contactDialog-btns-modify-container {
    position: fixed;
    top: initial;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1000;
    width: 100%;
    padding: 1.5rem;

    #teamsDefault & {
      background-color: rgb(240, 240, 240);
    }

    #teamsDark & {
      background-color: rgb(32, 31, 31);
    }
  }

  .contactDialogs-tabs-items {
    overflow-x: scroll !important;
    min-height: 40px;
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
