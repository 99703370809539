.contactImageLoader-container {
  z-index: 1000000;
}

.contactImageLoader-btn-container {
  z-index: 1000000;
}

.contactImageLoader-loaded-image-container {
  position: relative;
}

.contactImageLoader-hover {
  position: absolute;
  top: 20px;
  left: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: 0.1s ease;
}

.contactImageLoader-hover:hover[data-read-only~="false"] {
  opacity: 1;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .contactImageLoader-btn-container {
    position: initial !important;
    transform: none !important;
    top: 0;
    left: 0;
  }

  .contactImageLoader-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contactImageLoader-hover {
    position: absolute;
    top: 22.5px;
    left: 0;
    left: 0px;
    border-radius: 50%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: 0.1s ease;
  }
}
