.contactButton-disable-on-phone:first-child button {
  padding: 0 !important;
  min-width: 0 !important;
  margin-right: 0.5rem !important;
}

@media screen and (max-width: 768px) {
  .contactButton-disable-on-phone[data-responsive~="true"] {
    display: none !important;
  }
}
