

.table-table {
  border-collapse: collapse;
}


/* Actions */

  .table-action-container{
    display: flex!important;
    justify-content: flex-end!important;
  }


/* Header */

.table-header {
  border-radius: 8px;
}

.table-header tr th {
  font-weight: 300;
  font-size: .8rem;
}

/* Rows */

.table-rows {
  cursor: pointer;
}

.table-rows:hover {
  background-color: rgb(243, 243, 243);
}

#teamsDark .table-rows:hover {
  background-color: rgb(38, 38, 38);
}

.table-rows td {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

@keyframes fadeIn {
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0px);
  }
}
