.contact-top-container {
  position: fixed;
  width: 100%;
  z-index: 100;

  #teamsDefault & {
    background-color: rgb(240, 240, 240);
  }

  #teamsDark & {
    background-color: rgb(32, 31, 31);
  }
}

#new-teams .contact-top-container {
  #teamsDefault & {
    background-color: rgb(255, 255, 255) !important;
  }

  #teamsDark & {
    background-color: rgb(32, 31, 31) !important;
  }
}

#old-teams .contact-top-container {
  #teamsDefault & {
    background-color: rgb(255, 255, 255) !important;
  }

  #teamsDark & {
    background-color: rgb(0, 0, 0) !important;
  }
}

.contact-bottom-container {
  padding-top: 7rem;
  padding-bottom: 1rem;
  position: relative;
  min-height: calc(100vh - 7rem);

  .contact-contact-loader {
    position: absolute;
    top: 7rem;
    width: 100%;
    z-index: 100;
  }
}

#new-teams .contact-contact-loader {
  #teamsDefault & {
    background-color: rgb(255, 255, 255) !important;
  }

  #teamsDark & {
    background-color: rgb(32, 31, 31) !important;
  }
}

#old-teams .contact-contact-loader {
  #teamsDefault & {
    background-color: rgb(255, 255, 255) !important;
  }

  #teamsDark & {
    background-color: rgb(0, 0, 0) !important;
  }
}

.contact-bottom-container[data-is-premium~="true"] {
  padding-top: 5rem;
  min-height: calc(100vh - 5rem);

  .contact-contact-loader {
    top: 5rem;
  }
}

@media screen and (max-width: 768px) {
  .contact-bottom-container {
    padding-top: 10rem;
    min-height: calc(100vh - 10rem);

    .contact-contact-loader {
      top: 10rem;
    }
  }

  .contact-bottom-container[data-is-premium~="true"] {
    padding-top: 8rem;
    min-height: calc(100vh - 8rem);
    .contact-contact-loader {
      top: 8rem;
    }
  }
}
