.groups-list-container {
  min-height: 100px;
  border-radius: 8px;
  padding: 0.75rem;
  margin-top: 1rem;

  #teamsDefault & {
    background-color: rgb(240, 240, 240);
  }

  #teamsDark & {
    background-color: rgb(32, 31, 31);
  }
}

#groupMemberInput-container[data-contact-is-not-empty~="true"] {
  .ui-dropdown__item__header {
    display: none !important;
  }
}

#groupMemberInput-container {
  .ui-dropdown__selecteditem {
    display: none;
  }
}
