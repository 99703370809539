.importContact-bubble {
  width: 5px;
  height: 5px;
  border-radius: 50%;

  #teamsDefault & {
    background-color: black;
  }

  #teamsDark &,
  #teamsHighContrast & {
    background-color: white;
  }
}
