.groupAdministrationBottom-container {
  margin-top: 6rem;
  padding: 1rem;
  border-radius: 5px;
}

#new-teams .groupAdministrationBottom-container {
  #teamsDefault & {
    background-color: rgb(245, 245, 245) !important;
  }

  #teamsDark & {
    background-color: rgb(24, 24, 24) !important;
  }
}

#old-teams .groupAdministrationBottom-container {
  #teamsDefault & {
    background-color: rgb(245, 245, 245) !important;
  }

  #teamsDark & {
    background-color: rgb(24, 24, 24) !important;
  }
}
