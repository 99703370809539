.dialogBanner-container {
  padding: 0.5rem 1rem;
  width: calc(100% - 2rem);
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  border-radius: 7px;
  opacity: 0;
  animation-duration: 0.3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  z-index: 1000000000;
  border: 1px solid black;

  #teamsDefault & {
    background-color: rgb(91, 95, 199) !important;
  }

  #teamsDark & {
    background-color: rgb(98, 100, 167) !important;
  }

  #teamsHighContrast & {
    background-color: black;
    border: 2px solid white;
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }
}

.dialogBanner-left-container,
.dialogBanner-right-container {
  p,
  span {
    color: white !important;
  }
}

@media screen and (max-width: 500px) {
  .dialogBanner-container {
    position: absolute;
    bottom: initial;
    top: 1rem;
    flex-flow: column nowrap;
    padding: 1rem;

    & div:nth-child(1) {
      margin-bottom: 1rem;
    }
  }
}
