.groupAdministrationTop-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem;
  z-index: 100;
}

#new-teams .groupAdministrationTop-container {
  #teamsDefault & {
    background-color: rgb(255, 255, 255) !important;
  }

  #teamsDark & {
    background-color: rgb(32, 31, 31) !important;
  }
}

#old-teams .groupAdministrationTop-container {
  #teamsDefault & {
    background-color: rgb(255, 255, 255) !important;
  }

  #teamsDark & {
    background-color: rgb(0, 0, 0) !important;
  }
}

@media screen and (max-width: 768px) {
  .groupAdministrationTop-container {
    display: flex;
    gap: 1rem;
    flex-flow: column nowrap;
    width: 100%;
    justify-content: flex-start !important;
    align-items: flex-start !important;

    .groupAdministrationTop-right-container {
      width: 100%;

      & button {
        width: 100%;
        max-width: none;
      }
    }
  }
}
