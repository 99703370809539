.contact-card-container {
  width: 100%;
  max-width: 225px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  #teamsDefault & {
    background-color: rgb(245, 245, 245);
  }

  #teamsDark & {
    background-color: rgb(37, 36, 35);
  }
}

.contact-card-sail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 10;
  border-radius: 8px;

  .contact-card-sail-content {
    opacity: 0;
    transform: translateY(10px);
    transition: 0.15s cubic-bezier(1, 0, 0.5, 1);
  }

  &:hover .contact-card-sail-content {
    opacity: 1;
    transform: translateY(0px);
  }
}

.contact-card-container:hover .contact-card-bottom {
  #teamsDefault & {
    background-color: rgb(233, 233, 233);
  }

  #teamsDark & {
    background-color: rgb(43, 42, 42);
  }
}

.contact-card-top {
  width: 100%;
  height: 100px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  #teamsDefault & {
    background-color: rgb(91, 95, 199);
  }

  #teamsDark & {
    background-color: rgb(98, 100, 167);
  }

  .contact-card-picture,
  .contact-card-no-picture {
    width: 110px;
    height: 110px;
    transform: translateY(20px);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    object-fit: cover;
    border: 3px solid white;

    #teamsDefault & {
      border: 3px solid white;
    }

    #teamsDark & {
      border: 3px solid rgb(37, 37, 37);
    }
  }

  .contact-card-no-picture {
    border-radius: 50%;
    #teamsDefault & {
      background-color: rgb(240, 240, 240);
    }

    #teamsDark & {
      background-color: rgb(32, 31, 31);
    }
  }
}

.contact-card-bottom {
  padding: 2.25rem 1rem 1.5rem 1rem;
  border-radius: 8px;
}

.contact-card-menu-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

@media screen and (max-width: 500px) {
  .contact-card-container {
    max-width: none;
  }
}
