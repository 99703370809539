.navbar-inp-container {
  padding: 0 1rem;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .navbar-container {
    flex-flow: column nowrap !important;
  }

  .navbar-left {
    width: 100% !important;
    margin-bottom: 0.75rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    .ui-menubutton,
    button:nth-child(1) {
      width: 100%;
    }
  }
  .navbar-inp-container {
    padding: 0 1rem 0 0;
    width: 100%;
  }
}
